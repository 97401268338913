.intro a {
  @apply text-gray font-black;
}

.intro p {
  @apply mt-0 mb-4;
}

.intro ul p {
  @apply m-0;
}

.intro ul {
  @apply pl-4;
}

.intro ul li::marker {
  @apply font-black text-gray;
}
