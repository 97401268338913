@media screen(lg) {
  .courseModesLeftPanel::before {
    @apply bg-putty-200;
    bottom: 0;
    content: "";
    position: absolute;
    right: 66%;
    top: 0;
    transform: skewX(-12deg);
    transform-origin: top;
    width: 500%;
    z-index: -1;
  }
}
