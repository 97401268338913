.default {
  @apply bg-card;
}
.default h1,
.default h2,
.default h3,
.default h4,
.default h5,
.default h6 {
  @apply text-primary;
}
.default p,
.default li {
  @apply text-gray-dark;
}

.dark {
  @apply bg-primary;
}
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark p,
.dark li {
  @apply text-primary-foreground;
}

.dark-gradient {
  @apply bg-primary-gradient;
}
.dark-gradient h1,
.dark-gradient h2,
.dark-gradient h3,
.dark-gradient h4,
.dark-gradient h5,
.dark-gradient h6,
.dark-gradient p,
.dark-gradient li {
  @apply text-primary-foreground;
}
